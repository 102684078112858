// IMPORTS
@import (reference) "../settings.less";

@media (min-width: 1280px) {
    // FILTER ON CANVAS
    body[data-canvas-filter] {

        #filter {
            z-index: 10000;
            background-color: white;
            width: 290px;
            height: 100vh;
            overflow-y: auto;
            padding: 8px 12px;
            transition: transform .3s ease-in-out;
            color: white;

            > h3 {
                font-size: 24px;
                margin-bottom: 8px;
                font-weight: bold;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                font-family: @main-font-family;
            }

            .gs-filtr-container {

                label {

                    &:before {
                        border-color: lighten(@font-color, 30%) !important;
                    }
                }
            }

            .gs-filtr-container {
                border: 0;
                padding-left: 0;
                padding-right: 0;
                padding-top: 0 !important;
                padding-bottom: 8px !important;

                > strong {
                    display: block;
                    color: @header-color;
                    padding-block: 14px 6px;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 0.08em;
                    font-size: 12px;


                    span {
                        display: none;
                    }

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                &:has(#price-filter-handles) > strong {
                    padding-bottom: 0;
                }

                input {
                    display: none;
                }

                    input:checked + label {

                        &:before {
                            border-color: @sec-color;
                            border-width: 2px;
                        }

                        &:after {
                            border-bottom: 2px solid @header-color;
                            border-left: 2px solid @header-color;
                            width: 10px;
                        }
                    }

                label {
                    display: block;
                    padding: 7px 0 7px 29px;
                    line-height: 15px;
                    position: relative;
                    cursor: pointer;
                    user-select: none;
                    color: @font-color;

                    &:active {

                        &:before {
                            border-radius: 50%;
                        }
                    }

                    &:hover {

                        &:before {
                            border-width: 2px;
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 6px;
                        left: 0;
                        width: 18px;
                        height: 18px;
                        border: 1px solid @header-color;
                        transition: border-radius .1s ease-in-out;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        transform-origin: top left;
                        border: 0;
                        height: 7px;
                        width: 0;
                        left: 3px;
                        top: 15px;
                        transform: rotate(-45deg);
                        transition: width .2s ease;
                    }
                }

                &:not(.colors) {

                    ul {
                        max-height: 174px;
                        overflow: hidden;
                        overflow-y: auto;
                        scrollbar-width: thin;

                        &::-webkit-scrollbar {
                            width: 10px;
                            height: 8px;
                            background-color: @border-color;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: darken(@border-color, 20%);
                        }
                    }
                }

                &.colors {

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 4px;
                    }

                    li {
                        display: block;
                    }

                    label {
                        font-size: 0;
                        padding: 0;
                        width: 34px;
                        height: 34px;
                        border: 2px solid @border-color;
                        outline: 4px solid white;
                        outline-offset: -5px;

                        &:before,
                        &:after {
                            display: none;
                        }

                        &:hover {
                            border-color: @header-color;
                        }
                    }

                    input:checked + label {
                        border-color: @header-color;
                        outline-width: 3px;
                    }
                }
            }

            .price-filtering {

                #price-filter-amounts {
                    display: flex;

                    label,
                    i {
                        font-style: normal;
                        line-height: 36px;
                        margin-right: 8px;

                        &:last-of-type {
                            margin-left: 8px;
                        }
                    }

                        label:before {
                            display: none;
                        }

                    input {
                        display: block;
                        width: auto;
                        height: 36px;
                        width: 46px;
                        border: 1px solid @border-color;
                        background: transparent;
                        text-align: center;
                        padding: 0;
                        text-align: center;
                        flex-grow: 1;

                        &:nth-of-type(1) {
                            margin-right: 8px;
                        }
                    }
                }
            }
        }

        #container.show-side-filter #filter {
            transform: translateX(0);
        }

        #container .inner-wrapper {

            &:before {
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.25);
                z-index: 10000;
                opacity: 0;
                visibility: hidden;
                transition: opacity .2s ease-in-out;
            }
        }

        #container.show-side-filter .inner-wrapper:before {
            visibility: visible;
            opacity: 1;
        }

        .price-filtering #price-filter-amounts i {
            color: @header-color;
        }
    }

    #filter .gs-filtr-container {

        &.no-filters-found {

            &:after {
                content: 'brak filtrów...';
                color: @error-color;
                display: block;
            }
        }

        > input {
            height: 30px;
            margin-bottom: 5px;
            padding: 0 10px;
            font-size: 13px;
        }

        label {

            &.hide {
                display: none !important;
            }
        }
    }
}

// PRICE FILTERING (both filter types)
#price-filter-handles { //line
    height: 4px;
    margin-block: 18px;
    margin-inline: 3px 17px;
    background-color: @border-color;
    border: 0;
    border-radius: 0;
}

.price-filtering .noUi-horizontal .noUi-handle {
    box-shadow: none;
    cursor: grab;
    background-color: @sec-color;
    border-radius: 4px;
    border: 0;
    width: 16px;
    height: 16px;
    top: calc(50% + 2px);
    transform: translateY(-50%);


    &.noUi-active {
        cursor: grabbing;
        background: darken(@sec-color, 10%);
    }

    &:before,
    &:after {
        display: none;
    }
}

#price-filter-amounts {
    display: flex;
    justify-content: space-between;

    label,
    i {
        font-style: normal;
        line-height: 33px;
        color: @font-color;
    }

    label {
        padding: 0 !important;
    }

        label:before {
            display: none;
        }

    input {
        display: block;
        height: 33px;
        border: 1px solid @border-color;
        background: transparent;
        text-align: center;
        padding: 0;
        text-align: center;
        width: 68px;
    }
}

// PILLS
.pills-group {

    &:before {
        content: attr(data-pills-group-name) + ':';
        color: @header-color;
        font-size: 12px;
        font-weight: 600;
        display: block;
    }
}

.single-pill {
    background-color: @light-bg;
    display: inline-flex;
    margin: 4px 4px 0 0;
    align-items: center;
    font-size: 14px;
    padding: 3px 10px;
    cursor: pointer;
    border-radius: var(--borderRadius);

    &:after {
        content: '\e803';
        font-family: icomoon;
        font-size: 9px;
        margin-left: 8px;
    }

    &:nth-of-type(1) {
        clear: left;
    }

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        background-color: @border-color;
    }
}

.remove-all-filters {
    padding: 3px 0;
    cursor: pointer;
    color: @header-color;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;

    &:before {
        content: '\e803';
        font-family: icomoon;
        font-weight: normal;
        font-size: 9px;
        margin-right: 8px;
    }

    &:hover {
        color: @error-color;
    }
}


// SIDEBAR BANNERS
.sidebar-banners-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.sidebar-banner {
    width: 260px;
    aspect-ratio: ~"260/160";
    position: relative;

    img {
        .img-crop();
    }

    .banner-text-wrapper {
        bottom: 16px;
        padding: 8px;
        margin-inline: 16px;
        width: calc(100% - 32px);

        b {
            font-size: 12px;
        }
    }
}

// PAGINATION
.pagination {
    display: flex;
    justify-content: center;
    gap: 56px;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        padding-inline: 5px;
        height: 40px;
        background-color: white;
        border: 1px solid @border-color;
        color: @header-color;
    }

    > a {

        &.pagination-prev:after {
            transform: rotate(90deg);
        }

        &.pagination-next:after {
            transform: rotate(-90deg);
        }

        &:after {
            content: "\e91c";
            font-family: icomoon;
            font-size: 24px;
        }
    }

    ul {
        display: flex;
        gap: 4px;
    }

    li {
        display: none;

        @media (min-width: 601px) {
            display: block;
        }

        &:first-child,
        &.selected,
        &.prev-to-selected,
        &.next-to-selected,
        &:last-child {
            display: block;
        }

        &:nth-child(2):not(.next-to-selected):not(.selected):not(.prev-to-selected),
        &:nth-last-child(-n+2):not(:last-child):not(.next-to-selected):not(.selected):not(.prev-to-selected) {
            position: relative;
            display: block;

            &:before {
                content: '...';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: white;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                font-size: 22px;
            }
        }
    }

    a:hover {
        border: 1px solid @main-color;
    }

    li.selected > a {
        color: @main-color;
        font-weight: 700;
        border-color: @main-color;
    }
}